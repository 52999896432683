$(function() {

	window.App = new Vue({
		el: '#app',
		data: function() {
			return {
				appObj: {
					user: {
						level: null,
						user_id: null,
						firstname: null,
						surname: null,
						email: null
					}
				}
			};
		},
		created: function() {
			this.start();
		},
		methods: {
			start: function(e) {
				this.appObj = JSON.parse(JSON.stringify(window.AppStandard.appObj));
			}
		}
	});
});